<template>
  <div class="event-info">
    <div class="info-items">
      <div class="time">
        <span>{{ startTime }}</span> ~ <span>{{ endTime }}</span>
      </div>
      <div class="group">
        <div class="group-title">{{ $t('device')/*設備*/ }}</div>
        <div class="items">
          <div class="item">
            <img src="@/assets/icons/Group.svg" alt="">
            <span class="group-name">{{ getGroupName(event.groupId) }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('history_device')/*辨識設備*/" alwaysShow>
              <img src="@/assets/icons/Camera.svg" alt="">
            </TooltipElement>
            <span>{{ getEventTitleId(event.userAccount) }}</span>  
          </div>
          <div class="item">
            <TooltipElement :content="$t('history_fr_gps')/*GPS位置*/" alwaysShow>
              <img src="@/assets/icons/gps.svg" alt="">
            </TooltipElement>
            <span>{{ event.gps.latitude }}, {{ event.gps.longitude }}</span>
            <img src="@/assets/icons/google-map-icon.png" @click="goGoogleMap" alt="">
          </div>
        </div>
      </div>
      <div class="group">
        <div class="group-title">{{ $t('link')/*勾稽*/ }}</div>
        <div class="items">
          <div class="item">
            <TooltipElement :content="$t('history_user_group')/*使用者群組*/" alwaysShow>
              <img src="@/assets/icons/Group.svg" alt="">
            </TooltipElement>
            <span class="group-name">{{ event.linkedGroupName }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('history_link_user')/*勾稽使用者*/" alwaysShow>
              <img src="@/assets/icons/user.svg" alt="">
            </TooltipElement>
            <span v-if="event.linkedUserName">{{ `${ event.linkedUserName } (${ event.linkedUserAccount })` }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import { apiEditBoviaLprEvent } from '@/api/index.js'

export default {
  name: 'EventInfoSos',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    event: {
      type: Object
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['groupList']),
    ...mapGetters(['getEventTitleId', 'timezone']),
    startTime() {
      return moment(this.event.startTime).tz(this.timezone).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
    endTime() {
      return moment(this.event.endTime).tz(this.timezone).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
  },
  methods: {
    async setEventMisjudged(event) {
      let data = {
        event: [{id: event.id}],
        misjudged: event.misjudged === 1 ? 0 : 1
      }
      let res = await apiEditBoviaLprEvent(data)
      if (res.status === 204) {
        event.misjudged = event.misjudged === 1 ? 0 : 1
      }
    },
    goGoogleMap() {
      const url = `https://www.google.com.tw/maps/search/?api=1&query=${this.event.gps.latitude},${this.event.gps.longitude}`
      window.open(url, '_blank')
    },
    getGroupName(groupId) {
      const group = this.groupList.find((item) => item.id === groupId)
      return group ? group.name : ''
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.event-info {
  width: 100%;
  height: 100%;
  padding-right: 20px;
  display: flex;
  column-gap: 32px;
  box-sizing: border-box;

  .info-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    font-size: px2rem(20);
    line-height: 36px;
    @include xxl {
      font-size: px2rem(20);
      line-height: 30px;
    }
  }

  span {
    font-size: px2rem(20);
    @include xxl {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .plate {
    position: relative;
    display: flex;
    align-items: center;
  }

  .plate-code {
    font-size: px2rem(24);
    font-weight: 700;
    line-height: 33px;
    color: #FFE99F;
    @include xxl {
      font-size: 24px;
    }
  }

  .plate-icon {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
  
  .time {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }
  .time span {
    display: inline-block;
    background: #4A5C78;
    border-radius: 15px;
    padding: 2px 12px 3px;
    font-size: px2rem(18);
    line-height: 24px;
  }

  .info {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    font-size: px2rem(20);
    @include xxl {
      font-size: 20px;
    }
  }

  .info img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  .subject {
    font-size: 20px;
    line-height: 24px;
    color: #FFE99F;
    margin-top: 8px;
    @include xxl {
      font-size: 15px;
    }
  }

  .group-name {
    font-weight: 400;
    color: #FFC600;
    white-space: nowrap;
    margin-right: 8px;
  }

  .video-title {
    display: flex;
    width: 100%;
    font-weight: 300;
    span {
      width: 100%;
      font-weight: 300;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .gps img ~ img {
    margin-left: 20px;
    cursor: pointer;
  }

  

  .note span {
    width: 100%;
    font-weight: 300;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .separater {
    width: 100%;
    height: 2px;
    border-top: 1px solid #ffffff33;
  }

  .message {
    display: flex;
    align-items: center;
  }

  .push-content span {
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}

.group {
  display: flex;
  column-gap: 20px;
  padding: 8px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #ffffff33;
  }
  .group-title {
    width: px2rem(80);
    font-size: px2rem(20);
    line-height: px2rem(24);
    color: #FFE99F;
    font-weight: 400;
  }
  .items {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    .item {
      display: flex;
      align-items: flex-start;
      column-gap: 12px;
      font-size: px2rem(20);
      line-height: px2rem(32);
      color: #ffffff;
      font-weight: 400;
      img {
        width: px2rem(24);
        height: px2rem(24);
        margin-top: px2rem(5);
      }
      span {
        font-size: px2rem(20);
      }
    }
  }
}

.tags {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 6px;
  margin-bottom: 4px;
}

.tags span {
  display: flex;
  align-items: center;
  background: #ffffff33;
  padding: 1px 12px 2px 12px;
  border-radius: 20px;
  margin-right: 8px;
  white-space: nowrap;
}
</style>